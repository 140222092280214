<template>
  <div>
    <!-- 欢迎您，{{userInfo.userName}} -->
    <div class="tips_text">
      <div><img src="@/assets/images/logo2.png" alt="慧图资环" /></div>
      <div class="tip_textbox">欢迎使用公司管理平台</div>

      <div v-if="backlogIsShow">
        <el-alert
          :closable="false"
          show-icon
          title="当前您有未处理的费用待办，请及时处理"
          style="width: 122%; margin-bottom: 10px"
          type="warning"
        >
        </el-alert>
        <el-button
          type="primary"
          @click="expensebacklogIshwow = true"
          size="medium"
          style="position: relative"
          >点击前往费用待办页面 <i class="Red"></i
        ></el-button>
      </div>
    </div>
    <Edit v-if="expensebacklogIshwow" :expensebacklogIshwow.sync="expensebacklogIshwow"></Edit>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Edit: () => import('@/views/expenseBacklog/Edit'),
  },
  data() {
    return {
      expensebacklogIshwow: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      backlogIsShow: state => state.user.backlogIsShow,
    }),
    isTipShow() {
      return this.$route.query.tip
    },
  },
  created() {},
  methods: {},
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.tips_text {
  position: absolute;
  top: 45%;
  right: 45%;
  text-align: center;
  transform: translateY(-50%);

  .tip_textbox {
    padding: 5px;
  }
}
.Red {
  position: absolute;
  top: 2px;
  right: 3px;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: red;
  vertical-align: middle;
}
</style>
